import { IconButton } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";

function SocialIcons() {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton size="small" href="mailTo:sidsaini1196@gmail.com">
          <EmailIcon />
        </IconButton>
        <IconButton
          size="small"
          style={{ margin: "0rem 0.5rem" }}
          href="https://github.com/rohitsaini1196"
          target="__blank"
        >
          <GitHubIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          href="https://www.linkedin.com/in/rohitsaini1196/"
          target="__blank"
        >
          <LinkedInIcon />
        </IconButton>
      </div>
    </div>
  );
}
export default SocialIcons;
