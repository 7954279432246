import React, { useState, useEffect } from "react";
import SearchFilterArea from "../components/SearchFilterArea";
import FeedsArea from "../components/FeedsArea";
import { Typography, Button } from "@material-ui/core";
import firebase from "../config/firebase";

function Home({ handleClickOpen }) {
  const [isLoggedin, setIsLoggedin] = useState(false);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setIsLoggedin(true);
      }
    });
    return unsubscribe;
  }, []);
  return (
    <div>
      <SubtitleAndCreate
        handleClickOpen={handleClickOpen}
        isAdmin={isLoggedin}
      />
      <SearchFilterArea />
      <FeedsArea isAdmin={isLoggedin} />
    </div>
  );
}

export default Home;

function SubtitleAndCreate({ handleClickOpen, isAdmin }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ padding: "1rem 0rem 0rem 0rem" }}>
        <Typography style={{ fontWeight: 530, fontSize: 20 }}>
          Things I want to remember &nbsp; &cap; &nbsp; Things I don’t mind the
          internet knowing
        </Typography>
      </div>
      {isAdmin ? (
        <div>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            color="primary"
            onClick={handleClickOpen}
          >
            Create
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
