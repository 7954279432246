import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyBPhp20tSN2bdel9lrXNY7Zs19EROYB-Dg",
  authDomain: "feed-only-blog.firebaseapp.com",
  projectId: "feed-only-blog",
  storageBucket: "feed-only-blog.appspot.com",
  messagingSenderId: "985158671755",
  appId: "1:985158671755:web:0896a896d06e2cb67ee38e"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
