import React, { useState, useEffect } from "react";
import {
  Dialog,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import firebase from "../config/firebase";

function Login({ open, handleClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState({});
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [error, setError] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    //console.log({email, password});
    setError("");
    if (email && password) {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          setEmail("");
          setPassword("");
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        //console.log(u);
        setUser(u);
        setIsLoggedin(true);
      } else {
        setIsLoggedin(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <form onSubmit={submitForm}>
          <DialogTitle id="alert-dialog-title">{"Login as Admin"}</DialogTitle>
          <DialogContent>
            <div>
              <div>
                {error ? (
                  <div style={{ padding: "0rem 1rem 1rem 1rem" }}>
                    <Typography style={{ color: "pink" }}>{error}</Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {!isLoggedin ? (
                  <div>
                    <div>
                      <TextField
                        required
                        style={{ margin: "0.5rem 0.5rem" }}
                        variant="outlined"
                        label="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        size="small"
                      />
                      <TextField
                        required
                        style={{ margin: "0.5rem 0.5rem" }}
                        variant="outlined"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <Typography>
                      {" "}
                      Loggedin as User : <strong>{user.email}</strong>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            {isLoggedin ? (
              <Button
                onClick={logout}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
              >
                Logout
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                type="submit"
              >
                Login
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default Login;
