import React, { useState, useEffect } from "react";
import {
  Dialog,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
} from "@material-ui/core";
import firebase from "../config/firebase";
import FeedDataService from "../services/feed";
import Moment from "react-moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Contact({ handleClose, open }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [error, setError] = useState("");
  const [contacts, setContacts] = useState("");

  const clearForm = () => {
    setEmail("");
    setName("");
    setQuery("");
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log({ email, name, query });
    setError("");
    if (email && name && query) {
      FeedDataService.createContact(name, email, query);
      handleClose();
      clearForm();
      setTimeout(() => {
        alert(
          "Thanks for contacting. I'll try to respond asap via email if your message is resonable."
        );
      }, 1000);
    } else {
      setError("Email, Name and Message can't be empty");
      return;
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setIsLoggedin(true);
        const contactRef = firebase.database().ref("contact");
        contactRef.on("value", function (snapshot) {
          var localContacts = [];
          snapshot.forEach((doc) => {
            var obj = doc.val();
            obj.parent = doc.ref.key;
            localContacts.push(obj);
          });
          setContacts(localContacts.reverse());
        });
      } else {
        setIsLoggedin(false);
      }
    });
    return unsubscribe;
  }, []);
  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <form onSubmit={submitForm}>
          <DialogTitle id="alert-dialog-title">{"Contact me"}</DialogTitle>
          <DialogContent>
            <div>
              <div>
                {error ? (
                  <div style={{ padding: "0rem 1rem 1rem 1rem" }}>
                    <Typography style={{ color: "pink" }}>{error}</Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      style={{ margin: "1rem 0.5rem 1rem 0rem" }}
                      label="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      size="small"
                    />
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      type="email"
                      style={{ margin: "1rem 0rem 1rem 0.5rem" }}
                      label="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      size="small"
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      fullWidth
                      multiline
                      maxRows={4}
                      minRows={3}
                      variant="outlined"
                      label="Message"
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none", marginRight: "1rem" }}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </form>

        {isLoggedin ? (
          <div style={{ padding: "1rem" }}>
            <ContactTable contacts={contacts} />
          </div>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}

export default Contact;

function ContactTable({ contacts }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Name & Email</StyledTableCell>
            <StyledTableCell>Time</StyledTableCell>
            <StyledTableCell align="right">Message</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <StyledTableRow key={contact.parent}>
              <StyledTableCell>
                {contact.name} <small>{contact.email}</small>
              </StyledTableCell>
              <StyledTableCell>
                <Moment format="DD MMM YYYY  HH:mm">
                  {contact.timeCreated}
                </Moment>
              </StyledTableCell>
              <StyledTableCell>{contact.message}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
