import React, { useState } from "react";
import { Container } from "@material-ui/core";
import Home from "../pages/Home";
import Navbar from "./Navbar";
import Create from "./Create";
import Footer from "./Footer";
import Login from "./Login";
import Contact from "./Contact";
function Layout() {
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleClickContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };
  return (
    <div>
      <Container maxWidth="md">
        <Navbar />
        <Create open={open} handleClose={handleClose} />
        <Home handleClickOpen={handleClickOpen} />

        <Footer
          handleClickLogin={handleClickLogin}
          handleClickContact={handleClickContact}
        />
        <Login open={openLogin} handleClose={handleCloseLogin} />
        <Contact open={openContact} handleClose={handleCloseContact} />
      </Container>
    </div>
  );
}

export default Layout;
