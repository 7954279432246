import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BorderColorIcon from "@material-ui/icons/BorderColor";

import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import FeedDataService from "../services/feed";

export default function Create({ open, handleClose }) {
  const [feedText, setFeedText] = useState("");
  const [type, setType] = useState("public");
  const [preview, setPreview] = useState(false);
  const changeFeedText = (e) => {
    setFeedText(e.target.value);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const clearForm = () => {
    setFeedText("");
  };

  const submitForm = () => {
    if (!feedText) {
      alert("Feed Text can't be empty");
      return;
    }
    console.log(
      "Creating feed for " + feedText + ". this post is " + type + "."
    );
    var mood = "Normal";
    FeedDataService.createFeed(feedText, type, mood);
    clearForm();
    handleClose();
  };
  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{"Create new Feed"}</DialogTitle>
        <DialogContent>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ padding: "0.5rem 0rem" }}>
                <FormControl size="small" variant="outlined">
                  <Select value={type} onChange={handleChangeType}>
                    <MenuItem value={"private"}>Private</MenuItem>
                    <MenuItem value={"public"}>Public</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    setPreview(!preview);
                  }}
                >
                  {preview ? <BorderColorIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>
            </div>
            <div>
              {preview ? (
                <div>
                  <ReactMarkdown remarkPlugins={[gfm]} children={feedText} />
                </div>
              ) : (
                <div>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={5}
                    minRows={3}
                    variant="outlined"
                    value={feedText}
                    onChange={changeFeedText}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitForm} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
