import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import firebase from "../config/firebase";
import FeedItem from "./FeedItem";

function FeedsArea({ isAdmin }) {
  const [feeds, setFeeds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const feedRef = firebase.database().ref("feeds");
    feedRef.on("value", function (snapshot) {
      var localFeeds = [];
      snapshot.forEach((doc) => {
        var obj = doc.val();
        obj.parent = doc.ref.key;
        localFeeds.push(obj);
      });
      setFeeds(localFeeds.reverse());
      setIsLoading(false);
    });
  }, []);
  return (
    <div>
      {isLoading ? (
        <div style={{ padding: "4rem 2rem", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {feeds.map((feed) => (
            <FeedItem key={feed.feedId} feed={feed} isAdmin={isAdmin} />
          ))}
        </div>
      )}
    </div>
  );
}

export default FeedsArea;
