import { Typography } from "@material-ui/core";
import React from "react";
function Footer({ handleClickLogin, handleClickContact }) {
  return (
    <div>
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <div>
          <Typography color="textSecondary" component="div">
            Rohit Saini &#8226; &copy; 2021 &#8226;  {" "}
            <span
              style={{ cursor: "pointer", color: "#23C4ED" }}
              onClick={handleClickLogin}
            >
              StillThirsty{" "}
            </span>
            &#8226;{" "} 
            <span
              style={{ cursor: "pointer", color: "#23C4ED" }}
              onClick={handleClickContact}
            >
              Contact
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
