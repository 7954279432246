import firebase from "../config/firebase";
import { v4 as uuid } from "uuid";

class FeedDataService {
  async createFeed(feedText, type, mood) {
    var dateInt = Date.now();
    var feedRef = firebase.database().ref("feeds");
    var newFeedObj = {
      feed: feedText,
      mood: mood,
      type: type,
      feedId: uuid(),
      timeCreated: dateInt,
      timeUpdated: dateInt,
    };
    var res = await feedRef.push(newFeedObj);
    return res;
  }

  async updateFeed(fId, feedText, type) {
    var dateInt = Date.now();
    var feedRef = firebase.database().ref("feeds");
    var updatedFeed = await feedRef.child(fId).update({
      feed: feedText,
      type: type,
      timeUpdated: dateInt,
    });
    return updatedFeed;
  }

  async deleteFeed(fId) {
    var feedRef = firebase.database().ref("feeds");
    var deletedFeed = await feedRef.child(fId).remove();
    return deletedFeed;
  }

  async changeFeedType(fId, type) {
    var feedRef = firebase.database().ref("feeds");
    var changeTypeFeed = await feedRef.child(fId).update({
      type: type,
    });
    return changeTypeFeed;
  }

  async createContact(name, email, message) {
    var conRef = firebase.database().ref("contact");
    var dateInt = Date.now();
    var newContactObj = {
      name: name,
      email: email,
      message: message,
      timeCreated: dateInt,
    };
    var res = await conRef.push(newContactObj);
    return res;
  }
}

export default new FeedDataService();
