import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Button,
  Chip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FeedService from "../services/feed";
import moment from "moment";

function FeedItem({ feed, isAdmin }) {
  const [open, setOpen] = useState(false);

  useEffect(()=>{

  },[])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeDateString = () => {
    return moment.unix(feed.timeCreated / 1000).format("DD-MMM-YYYY-HH-mm");
  };
  return (
    <div>
      {(feed.type !== "private" || isAdmin) ? (<Paper
      style={{ padding: "1rem 0.5rem 1rem 1rem", margin: "1rem 0rem" }}
      id={makeDateString()}
    >
      <FeedHeader
        timeCreated={feed.timeCreated}
        isAdmin={isAdmin}
        fId={feed.parent}
        editOpenFeed={handleClickOpen}
        type={feed.type}
        dateId={makeDateString()}
      />
      <UpdateFeed open={open} handleClose={handleClose} feed={feed} />
      <FeedContent feedText={feed.feed} />
    </Paper>):""}
    </div>
  );
}

export default FeedItem;

function FeedHeader({ timeCreated, isAdmin, fId, editOpenFeed, type, dateId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [opType, setOpType] = useState("");

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteFeedLocal = (fId) => {
    FeedService.deleteFeed(fId);
  };

  const changeFeedType = () => {
    FeedService.changeFeedType(fId, opType);
  };
 
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  useEffect(() => {
    if (type === "public") {
      setOpType("private");
    } else {
      setOpType("public");
    }
  }, [type]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Typography style={{ fontWeight: 550, fontSize: 18 }} component={"div"}>
          <Moment format="DD MMM YYYY, HH:mm">{timeCreated}</Moment>
          {isAdmin ? (
            <Chip
              label={capitalizeFirstLetter(type)}
              disabled
              variant="outlined"
              color="primary"
              size="small"
              style={{ margin: "0rem 0.5rem" }}
            />
          ) : (
            ""
          )}
        </Typography>
      </div>
      <div>
        <IconButton size="small" onClick={handleClickMenu}>
          <MoreVertIcon />
        </IconButton>
        <div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                console.log(dateId);
                var link = window.location.host + "/#" + dateId;
                navigator.clipboard.writeText(link);
                alert("Link:  \n" + link + " \nCopied to clipboard");
              }}
            >
              Copy Link
            </MenuItem>
            <MenuItem onClick={handleCloseMenu}>Report</MenuItem>
            {isAdmin ? (
              <div>
                <MenuItem
                  onClick={() => {
                    editOpenFeed();
                    handleCloseMenu();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeFeedType();
                    handleCloseMenu();
                  }}
                >
                  Make {opType}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteFeedLocal(fId);
                  }}
                >
                  Delete
                </MenuItem>
              </div>
            ) : (
              ""
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
}

function FeedContent({ feedText }) {
  return (
    <div style={{padding:"0.5rem 1rem 0.5rem 0rem"}}>
      <Typography component="div" color="textSecondary">
      <ReactMarkdown remarkPlugins={[gfm]} children={feedText} />
    </Typography>
    </div>
  );
}

function UpdateFeed({ feed, open, handleClose }) {
  const [feedText, setFeedText] = useState(feed.feed);
  const [type, setType] = useState(feed.type);
  const [preview, setPreview] = useState(false);
  const changeFeedText = (e) => {
    setFeedText(e.target.value);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const submitForm = () => {
    if (!feedText) {
      alert("Feed Text can't be empty");
      return;
    }

    FeedService.updateFeed(feed.parent, feedText, type);
    handleClose();
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Create new Feed"}</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "0.5rem 0rem" }}>
              <FormControl size="small" variant="outlined">
                <Select value={type} onChange={handleChangeType}>
                  <MenuItem value={"private"}>Private</MenuItem>
                  <MenuItem value={"public"}>Public</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setPreview(!preview);
                }}
              >
                {preview ? <BorderColorIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
          </div>
          <div>
            {preview ? (
              <div>
                <ReactMarkdown remarkPlugins={[gfm]} children={feedText} />
              </div>
            ) : (
              <div>
                <TextField
                  className="textarea"
                  fullWidth
                  variant="outlined"
                  maxRows={5}
                  multiline={true}
                  value={feedText}
                  onChange={changeFeedText}
                />
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
