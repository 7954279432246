import React from "react";
import Layout from "./components/Layout";
import {
  MuiThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    secondary: {
      main: "#25CCF7",
    },
    primary: {
      main: "#23C4ED",
    },
    background: {
      default:"#1c1c1c",
      paper: "#23272a",
      
    }
  },
});

function Theme() {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Layout />
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default Theme;
