import React from "react";
import { Typography } from "@material-ui/core";
import SocialIcons from "./SocialIcons";

export default function Navbar() {
  return (
    <div style={{ margin: "1rem 0rem", padding: "1rem 0rem" }}>
      <Title />
      <SocialIcons />
    </div>
  );
}

function Title() {
  return (
    <Typography variant="h4" style={{ fontWeight: "550" }}>
      StillThristy
      <span style={{ fontSize: "16px", fontWeight: "500" }}>
        &nbsp; Rohit Saini
      </span>
    </Typography>
  );
}
